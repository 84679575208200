const ADMIN_WITHDRAWAL_TEMPLATE_CATEGORY_LIST = [
  { label: "기타", value: "apEtc" },
  { label: "해외파트너", value: "foreign" },
  { label: "국내파트너", value: "domestic" },
  { label: "관세사", value: "customs" },
  { label: "내륙운송사", value: "inland" },
  { label: "창고", value: "warehouse" },
  { label: "파트너 전체", value: "apAll" },
  { label: "세관", value: "customsDuty" },
  { label: "선사", value: "shipping" },
];

export { ADMIN_WITHDRAWAL_TEMPLATE_CATEGORY_LIST };
