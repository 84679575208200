import styled from "styled-components";

interface ContainerProps {}
const container = styled.span`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

export default {
  container,
};
