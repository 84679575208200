import { IS_UNDER_PRODUCTION } from "../../constants";

const FORWARDING_TABLE_PER_PAGE_KEY = "forwardingTablePerPage";

const IMPORT_SHIPMENT_INCOTERMS_LIST = [
  "FOB",
  "EXW",
  "FCA",
  "CFR",
  "CIF",
  "CPT",
  "CIP",
];

/**
 * 쉽다 풀필먼트 인천 제1센터의 zoneId
 * production 서버: 2728
 * dev 서버: 20262
 */
const ZONE_ID_OF_FULFILLMENT_CENTER_INCHEON_1 = (() =>
  IS_UNDER_PRODUCTION ? 2728 : 20262)();

const ZONE_NAME_OF_FULFILLMENT_CENTER_INCHEON_1 = "인천광역시 서구 신현원창동";

const ZONE_B_CODE_OF_FULFILLMENT_CENTER_INCHEON_1 = "2826011100";

const ZONE_H_ADDRESS_OF_FULFILLMENT_CENTER_INCHEON_1 = "인천 서구 신현원창동";

export {
  FORWARDING_TABLE_PER_PAGE_KEY,
  IMPORT_SHIPMENT_INCOTERMS_LIST,
  ZONE_ID_OF_FULFILLMENT_CENTER_INCHEON_1,
  ZONE_NAME_OF_FULFILLMENT_CENTER_INCHEON_1,
  ZONE_B_CODE_OF_FULFILLMENT_CENTER_INCHEON_1,
  ZONE_H_ADDRESS_OF_FULFILLMENT_CENTER_INCHEON_1,
};
