/**
 * 비회원이 조회한 스케줄 정보를 저장하는 로컬 스토리지의 키
 */
const GUEST_FREIGHT_VIEWER_KEY = "guestFreightViewer";

/**
 * 비회원이 하루에 조회할 수 있는 스케줄 조회 횟수
 */
const MAX_GUEST_FREIGHT_VIEW_COUNT_PER_DAY = 2;

const PER_PAGE_FOR_MOBILE = 5;

export {
  GUEST_FREIGHT_VIEWER_KEY,
  MAX_GUEST_FREIGHT_VIEW_COUNT_PER_DAY,
  PER_PAGE_FOR_MOBILE,
};
