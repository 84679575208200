import styled from "styled-components";

const container = styled.div`
  /* #app-portal 내의 다른 모달보다 상위에 위치하도록 설정 */
  position: relative;
  z-index: 99999;
`;

export default {
  container,
};
