import { FC } from "react";

interface CommonIconProps {
  color?: string;
  bgColor?: string;
  onClick?: (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
  style?: React.CSSProperties;
  className?: string;
}

interface IconPropsV1<IconType> extends CommonIconProps {
  type: IconType;
  size?: number;
  lat?: number;
  lng?: number;
}

type UseIconProps = {
  size?: number;
  width?: number;
} & CommonIconProps;

type IconComponentV1<IconType> = FC<IconPropsV1<IconType>>;

/**
 * Icon 별로 별도의 컴포넌트를 구성하는 v2 아이콘에서만 사용함
 */
const svgCommonStyle: React.CSSProperties = {
  position: "absolute",
  top: "0px",
  left: "0px",
  width: "100%",
  height: "auto",
};

function useIcon({ size, width, onClick, style }: UseIconProps) {
  let combinedStyle: React.CSSProperties = {};

  if (style) {
    combinedStyle = {
      ...style,
    };
  }

  if (size) {
    combinedStyle = {
      ...style,
      width: `${size}rem`,
      height: `${size}rem`,
    };
  }

  if (width) {
    combinedStyle = {
      ...style,
      width: `${width}px`,
      height: `${width}px`,
    };
  }

  function handelClick(e: React.MouseEvent<HTMLSpanElement, MouseEvent>) {
    if (onClick) {
      onClick(e);
    }
  }

  return { combinedStyle, handelClick, svgCommonStyle };
}

export default useIcon;

export type { IconComponentV1, IconPropsV1 };
