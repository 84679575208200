import { CurrencyCode } from "../../types/common/common";

import { IS_UNDER_PRODUCTION } from "../../constants";

/**
 * 전세계가 포함된 공통 통화코드 리스트. (국제 표준 (ISO 4217))
 * 순서는 가장 많이 쓰이는 USD, CNY, EUR, JPY, GBP 를 최상단으로 이후는 A-Z 순.
 * (순서도 모든 프로덕트가 동일할 것 같아 공통으로 정의)
 */
const CURRENCY_CODE_LIST: CurrencyCode[] = [
  "USD",
  "CNY",
  "EUR",
  "JPY",
  "GBP",
  "AED",
  "AFN",
  "ALL",
  "AMD",
  "ANG",
  "AOA",
  "ARS",
  "AUD",
  "AWG",
  "AZN",
  "BAM",
  "BBD",
  "BDT",
  "BGN",
  "BHD",
  "BIF",
  "BMD",
  "BND",
  "BOB",
  "BOV",
  "BRL",
  "BSD",
  "BTN",
  "BWP",
  "BYN",
  "BYR",
  "BZD",
  "CAD",
  "CDF",
  "CHE",
  "CHF",
  "CHW",
  "CLF",
  "CLP",
  "COP",
  "COU",
  "CRC",
  "CUC",
  "CUP",
  "CVE",
  "CZK",
  "DJF",
  "DKK",
  "DOP",
  "DZD",
  "EGP",
  "ERN",
  "ETB",
  "FJD",
  "FKP",
  "GEL",
  "GHS",
  "GIP",
  "GMD",
  "GNF",
  "GTQ",
  "GYD",
  "HKD",
  "HNL",
  "HTG",
  "HUF",
  "IDR",
  "ILS",
  "INR",
  "IQD",
  "IRR",
  "ISK",
  "JMD",
  "JOD",
  "KES",
  "KGS",
  "KHR",
  "KMF",
  "KPW",
  "KRW",
  "KWD",
  "KYD",
  "KZT",
  "LAK",
  "LBP",
  "LKR",
  "LRD",
  "LSL",
  "LYD",
  "MAD",
  "MDL",
  "MGA",
  "MKD",
  "MMK",
  "MNT",
  "MOP",
  "MRO",
  "MUR",
  "MVR",
  "MWK",
  "MXN",
  "MXV",
  "MYR",
  "MZN",
  "NAD",
  "NGN",
  "NIO",
  "NOK",
  "NPR",
  "NZD",
  "OMR",
  "PAB",
  "PEN",
  "PGK",
  "PHP",
  "PKR",
  "PLN",
  "PYG",
  "QAR",
  "RON",
  "RSD",
  "RUB",
  "RWF",
  "SAR",
  "SBD",
  "SCR",
  "SDG",
  "SEK",
  "SGD",
  "SHP",
  "SLL",
  "SOS",
  "SRD",
  "SSP",
  "STD",
  "SVC",
  "SYP",
  "SZL",
  "THB",
  "TJS",
  "TMT",
  "TND",
  "TOP",
  "TRY",
  "TTD",
  "TWD",
  "TZS",
  "UAH",
  "UGX",
  "USN",
  "UYI",
  "UYU",
  "UZS",
  "VEF",
  "VND",
  "VUV",
  "WST",
  "XAF",
  "XAG",
  "XAU",
  "XBA",
  "XBB",
  "XBC",
  "XBD",
  "XCD",
  "XDR",
  "XOF",
  "XPD",
  "XPF",
  "XPT",
  "XSU",
  "XTS",
  "XUA",
  "XXX",
  "YER",
  "ZAR",
  "ZMW",
  "ZWL",
];

/**
 * 모바일 최대 너비
 * Small devices (landscape phones, less than 768px)
 */
const MOBILE_MAX_WIDTH = 767.98;

/**
 * @deprecated 태블릿 사이즈는 지원하지 않으므로 사용하지 말것
 * TODO: 사용코드가 없어지면 삭제
 * 태블릿 최대 너비
 * Medium devices (tablets, less than 992px)
 */
const TABLET_MAX_WIDTH = 991.98;

/**
 * 데스크톱 최소 너비 1440px
 */
const DESKTOP_MIN_WIDTH = 1440;

/**
 * 데스크톱 레이아웃이 변경되는 너비 1920px
 * - 1440 -> 1920px이 될 때 레이아웃이 변경됩니다.
 * - 1920 이후로는 레이아웃은 변하지 않고 flexible하게 컴포넌트의 width 가 증가합니다.
 */
const DESKTOP_FULL_WIDTH = 1920;

/**
 * 마이페이지 > 푸터 최대 너비
 * TODO: 푸터 리뉴얼시 삭제 예정
 */
const MY_PAGE_FOOTER_MAX_WIDTH = 1475;

/**
 * UNIX TIME 기준 년도
 */
const UNIX_TIME_YEAR = 1970;

/** 쉽다 웹 랜딩영역 데스크톱 최소너비 */
const DESKTOP_LANDING_PAGE_MIN_WIDTH = 1279;

/**
 * boful용 guide를 관리하는 S3 디렉토리의 URL을 개발/운영 환경에 따라 다르게 설정
 */
const S3_BOFUL_GUIDE_URL_BY_ENV = `https://sellernote-boful-guide${
  IS_UNDER_PRODUCTION ? "-prod" : "-dev"
}.s3.ap-northeast-2.amazonaws.com/guide`;

const ROAD_ADDRESS_OF_FULFILLMENT_CENTER_INCHEON_1 = "인천 서구 북항단지로 110";

const DETAIL_ADDRESS_OF_FULFILLMENT_CENTER_INCHEON_1 =
  "SGC (원창동 394-35) 쉽다 1센터";

const POSTAL_CODE_OF_FULFILLMENT_CENTER_INCHEON_1 = "22856";

export {
  CURRENCY_CODE_LIST,
  MOBILE_MAX_WIDTH,
  TABLET_MAX_WIDTH,
  DESKTOP_MIN_WIDTH,
  DESKTOP_FULL_WIDTH,
  MY_PAGE_FOOTER_MAX_WIDTH,
  UNIX_TIME_YEAR,
  DESKTOP_LANDING_PAGE_MIN_WIDTH,
  S3_BOFUL_GUIDE_URL_BY_ENV,
  ROAD_ADDRESS_OF_FULFILLMENT_CENTER_INCHEON_1,
  DETAIL_ADDRESS_OF_FULFILLMENT_CENTER_INCHEON_1,
  POSTAL_CODE_OF_FULFILLMENT_CENTER_INCHEON_1,
};
