import React from "react";
import ReactDOM from "react-dom";
import { FlattenSimpleInterpolation } from "styled-components";

import { IS_ON_BROWSER } from "../../constants";

export default function Portal({
  children,
  subContainerInfo,
  selector,
}: {
  children: React.ReactNode;
  selector: string;
  /**
   * 요소를 Portal의 `하위 container`에 추가하는 경우 사용한다.
   * (ex. portal에 추가되는 특정 요소들을 `하위 container`를 기준으로 정렬해야할때 사용)
   */
  subContainerInfo?: {
    /**
     * 하위 container의 id
     * - CSS선택자로 쓰임. `#`은 빼고 입력 (ex. `sds-v2-toast-list-container`)
     * - 최대한 unique하게(ex. id에 사용처를 담기) 쓴다
     */
    id: string;
    /**
     * 하위 container의 style을 지정할 수 있다.
     * - inline style로 지정할 수 있는 속성만 지정가능하다 (
     *   - `medea query` 지원 안 되므로, device별 스타일을 분기하여 전달하는 식으로 사용한다.
     */
    style?: FlattenSimpleInterpolation;
  };
}) {
  if (!IS_ON_BROWSER) return null;

  if (!children) return null;

  const container = document.querySelector(selector);

  if (!container) return null;

  const targetContainer = (() => {
    if (!subContainerInfo) return container;

    /**
     * 하위 컨테이너를 사용하는 경우, 전달받은 subContainerInfo로 Portal하위에 dom을 추가한다.
     */
    const subContainer = (() => {
      const existedSubContainer = container.querySelector(
        `#${subContainerInfo.id}`
      );

      if (existedSubContainer) {
        if (subContainerInfo.style) {
          // sub container용 스타일이 지정된 경우 적용 (모바일 분기 등이 될 수 있어 다시 style 적용해준다)
          existedSubContainer.setAttribute(
            "style",
            subContainerInfo.style.join("")
          );
        }

        return existedSubContainer;
      } else {
        // subContainer가 없으면 새로 만든다.
        const subContainer = document.createElement("div");
        subContainer.setAttribute("id", subContainerInfo.id);

        if (subContainerInfo.style) {
          // sub container용 스타일이 지정된 경우 적용
          subContainer.setAttribute("style", subContainerInfo.style.join(""));
        }

        // portal container 하위에 추가한다.
        container.appendChild(subContainer);

        return subContainer;
      }
    })();

    return subContainer;
  })();

  return ReactDOM.createPortal(children, targetContainer);
}
