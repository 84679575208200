import { useEffect, useRef, useState } from "react";

export default function useIntersectionObserver({
  threshold,
}: {
  threshold?: number;
}) {
  const [isActiveTarget, setIsActiveTarget] = useState(false);

  const target = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const options: IntersectionObserverInit = {
      root: null,
      rootMargin: "0px",
      threshold: threshold ?? 0.5,
    };

    const callback: IntersectionObserverCallback = (entries, observer) => {
      entries.forEach((entry) => {
        setIsActiveTarget(entry.isIntersecting);
      });
    };

    const io: IntersectionObserver = new IntersectionObserver(
      callback,
      options
    );

    if (target.current) {
      io.observe(target.current);
    }

    return () => io && io.disconnect();
  }, [threshold]);

  return { isActiveTarget, target };
}
