const LOCAL_STORAGE_KEY_FOR_SHIPDA_HERO_A_B_TEST_KEY =
  "2024-06-04-hero-section-A-B-test";

/**
 * 쉽다 AB테스트에 사용중인 browser storage key 리스트
 */
const LOCAL_STORAGE_KEY_LIST_FOR_SHIPDA_AB_TEST: string[] = [
  LOCAL_STORAGE_KEY_FOR_SHIPDA_HERO_A_B_TEST_KEY,
];

export {
  LOCAL_STORAGE_KEY_FOR_SHIPDA_HERO_A_B_TEST_KEY,
  LOCAL_STORAGE_KEY_LIST_FOR_SHIPDA_AB_TEST,
};
