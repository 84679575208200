import { useMemo } from "react";
import { useCallback, useState } from "react";

/**
 * Set(집합)을 state로 사용할수 있 게 해 줌
 */
export default function useSet<T>(initialValue?: T[]) {
  const [set, setSet] = useState(new Set(initialValue));

  const addToSet = useCallback(
    (val: T) => {
      const newSet = new Set(set);

      newSet.add(val);

      setSet(newSet);
    },
    [set]
  );

  const deleteFromSet = useCallback(
    (val: T) => {
      const newSet = new Set(set);

      newSet.delete(val);

      setSet(newSet);
    },
    [set]
  );

  const toggleFromSet = useCallback(
    (val: T) => {
      const newSet = new Set(set);

      if (newSet.has(val)) {
        newSet.delete(val);
      } else {
        newSet.add(val);
      }

      setSet(newSet);
    },
    [set]
  );

  const initSet = useCallback((values?: T[]) => {
    setSet(new Set(values));
  }, []);

  const array = useMemo(() => {
    if (!set) return [];

    return Array.from(set);
  }, [set]);

  return {
    /**
     * Set 값을 배열로 반환
     */
    array,
    set,
    add: addToSet,
    delete: deleteFromSet,
    toggle: toggleFromSet,
    init: initSet,
  };
}
