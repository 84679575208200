import { TableColumnFilterOptionList } from "../../types/fulfillment/common";

/**
 * dev: 단일상품 300만~, 그룹상품 600만~
 * prod: 단일상품 200만~, 그룹상품 500만~
 */
const FIRST_GROUP_SKU_ID_IN_DEV = 6000000;
const FIRST_GROUP_SKU_ID_IN_PROD = 5000000;

const ONE_MEGA_BYTE = 1024 * 1024; // 1MB

const MAX_UPLOAD_FILE_SIZE = 10 * ONE_MEGA_BYTE; // 10MB

/**
 * 국내 해외 판매채널
 * - 국내 판매채널 id 범위: 1~100
 * - 해외 판매채널 id 범위: 100~
 * - id 998 B2B는 국내 판매채널에만 해당
 * - id 999 기타는 국내 해외 판매채널 모두 해당
 */
const MALL_OPTION_RAW_DATA_LIST = [
  { id: 1, name: "auction", korName: "옥션" },
  { id: 2, name: "interpark", korName: "인터파크" },
  { id: 3, name: "hyundaiHomeShopping", korName: "현대홈쇼핑" },
  { id: 4, name: "10x10", korName: "텐바이텐" },
  { id: 5, name: "eleven", korName: "11번가" },
  { id: 6, name: "1300k", korName: "천삼백케이" },
  { id: 7, name: "AKmall", korName: "AK몰" },
  { id: 8, name: "AKDepartmant", korName: "AK백화점" },
  { id: 9, name: "CJHomeShopping", korName: "CJ홈쇼핑" },
  { id: 10, name: "GSHomeShopping", korName: "GS홈쇼핑" },
  { id: 11, name: "NSHomeShopping", korName: "NS홈쇼핑" },
  { id: 12, name: "SKStoa", korName: "SK스토아" },
  { id: 13, name: "WShopping", korName: "W쇼핑" },
  { id: 14, name: "YES24", korName: "예스24" },
  { id: 15, name: "godomall", korName: "고도몰" },
  { id: 16, name: "naverPay", korName: "네이버페이" },
  { id: 17, name: "lotteMart", korName: "롯데마트" },
  { id: 18, name: "lotteDepartmant", korName: "롯데백화점몰" },
  { id: 19, name: "lotteIMall", korName: "롯데아이몰" },
  { id: 20, name: "lotteOn", korName: "롯데온" },
  { id: 21, name: "musinsaStore", korName: "무신사스토어" },
  { id: 22, name: "smartStore", korName: "스마트스토어" },
  { id: 23, name: "styleShare", korName: "스타일쉐어" },
  { id: 24, name: "shinsegaeMall", korName: "신세계닷컴" },
  { id: 25, name: "ohnu", korName: "오늘의집" },
  { id: 26, name: "weMakePrice", korName: "위메프" },
  { id: 27, name: "emart", korName: "이마트" },
  { id: 28, name: "gmarket", korName: "지마켓" },
  { id: 29, name: "kakaoStore", korName: "카카오톡 스토어" },
  { id: 30, name: "cafe24", korName: "카페24" },
  { id: 31, name: "coupang", korName: "쿠팡" },
  { id: 32, name: "hnsMall", korName: "홈앤쇼핑" },
  { id: 33, name: "homePlus", korName: "홈플러스" },
  { id: 34, name: "imweb", korName: "아임웹" },
  { id: 35, name: "ABLY", korName: "에이블리" },
  { id: 36, name: "kakaoMakers", korName: "카카오메이커스" },

  { id: 100, name: "Amazon", korName: "아마존" },
  { id: 101, name: "BLIBLI", korName: "블리블리" },
  { id: 102, name: "Bukalapak", korName: "부칼라팍" },
  { id: 103, name: "Catch", korName: "캐치" },
  { id: 104, name: "Central", korName: "센트럴" },
  { id: 105, name: "Ebay", korName: "이베이" },
  { id: 106, name: "Etsy", korName: "엣시" },
  { id: 107, name: "Flipkart", korName: "플립카트" },
  { id: 108, name: "Fnac", korName: "프낙" },
  { id: 109, name: "IshopChangi", korName: "창이" },
  { id: 110, name: "JDCentral", korName: "제이디센트럴" },
  { id: 111, name: "JDID", korName: "제이디아이디" },
  { id: 112, name: "Jumia", korName: "주미아" },
  { id: 113, name: "Lazada", korName: "라자다" },
  { id: 114, name: "MetroDeal", korName: "메트로딜" },
  { id: 115, name: "Nykaa", korName: "니카" },
  { id: 116, name: "OnBuy", korName: "온버이" },
  { id: 117, name: "OZSale", korName: "오즈세일" },
  { id: 118, name: "PGMall", korName: "피지몰" },
  { id: 119, name: "PrestoMall", korName: "프레스토몰" },
  { id: 120, name: "QooTen", korName: "큐텐" },
  { id: 121, name: "Rakuten", korName: "라쿠텐" },
  { id: 122, name: "Shopee", korName: "쇼피" },
  { id: 123, name: "TheIconic", korName: "아이코닉" },
  { id: 124, name: "TIKI", korName: "티키" },
  { id: 125, name: "Tokopedia", korName: "토코피디아" },
  { id: 126, name: "Youbeli", korName: "유벨리" },
  { id: 127, name: "Zalora", korName: "자로라" },
  { id: 128, name: "91APP", korName: "91APP" },
  { id: 129, name: "Haravan", korName: "하라반" },
  { id: 130, name: "Magento", korName: "마젠토" },
  { id: 131, name: "Opencart", korName: "오픈카트" },
  { id: 132, name: "PrestaShop", korName: "프레스타샵" },
  { id: 133, name: "CommerceCloud", korName: "커머스클라우드" },
  { id: 134, name: "Shopify", korName: "쇼피파이" },
  { id: 135, name: "ShopifyPlus", korName: "쇼피파이플러스" },
  { id: 136, name: "WooCommerce", korName: "우커머스" },

  { id: 998, name: "B2B", korName: "B2B" },
  { id: 999, name: "etc", korName: "기타" },
] as const;

type SaleChannelName = typeof MALL_OPTION_RAW_DATA_LIST[number]["name"];
type SaleChannelKorName = typeof MALL_OPTION_RAW_DATA_LIST[number]["korName"];
const SALE_CHANNEL_MAP: Record<string, SaleChannelKorName> = {
  auction: "옥션",
  interpark: "인터파크",
  hyundaiHomeShopping: "현대홈쇼핑",
  "10x10": "텐바이텐",
  eleven: "11번가",
  "1300k": "천삼백케이",
  AKmall: "AK몰",
  AKDepartmant: "AK백화점",
  CJHomeShopping: "CJ홈쇼핑",
  GSHomeShopping: "GS홈쇼핑",
  NSHomeShopping: "NS홈쇼핑",
  SKStoa: "SK스토아",
  WShopping: "W쇼핑",
  YES24: "예스24",
  godomall: "고도몰",
  naverPay: "네이버페이",
  lotteMart: "롯데마트",
  lotteDepartmant: "롯데백화점몰",
  lotteIMall: "롯데아이몰",
  lotteOn: "롯데온",
  musinsaStore: "무신사스토어",
  smartStore: "스마트스토어",
  styleShare: "스타일쉐어",
  shinsegaeMall: "신세계닷컴",
  ohnu: "오늘의집",
  weMakePrice: "위메프",
  emart: "이마트",
  gmarket: "지마켓",
  kakaoStore: "카카오톡 스토어",
  cafe24: "카페24",
  coupang: "쿠팡",
  hnsMall: "홈앤쇼핑",
  homePlus: "홈플러스",
  imweb: "아임웹",
  ABLY: "에이블리",
  kakaoMakers: "카카오메이커스",

  Amazon: "아마존",
  BLIBLI: "블리블리",
  Bukalapak: "부칼라팍",
  Catch: "캐치",
  Central: "센트럴",
  Ebay: "이베이",
  Etsy: "엣시",
  Flipkart: "플립카트",
  Fnac: "프낙",
  IshopChangi: "창이",
  JDCentral: "제이디센트럴",
  JDID: "제이디아이디",
  Jumia: "주미아",
  Lazada: "라자다",
  MetroDeal: "메트로딜",
  Nykaa: "니카",
  OnBuy: "온버이",
  OZSale: "오즈세일",
  PGMall: "피지몰",
  PrestoMall: "프레스토몰",
  QooTen: "큐텐",
  Rakuten: "라쿠텐",
  Shopee: "쇼피",
  TheIconic: "아이코닉",
  TIKI: "티키",
  Tokopedia: "토코피디아",
  Youbeli: "유벨리",
  Zalora: "자로라",
  "91APP": "91APP",
  Haravan: "하라반",
  Magento: "마젠토",
  Opencart: "오픈카트",
  PrestaShop: "프레스타샵",
  CommerceCloud: "커머스클라우드",
  Shopify: "쇼피파이",
  ShopifyPlus: "쇼피파이플러스",
  WooCommerce: "우커머스",

  B2B: "B2B",

  etc: "기타",
};

/** ID 100 부터 해외 판매채널 */
const FIRST_OVERSEAS_MALL_ID = 100;
/** B2B 판매채널 (국내에서만 사용) */
const B2B_MALL_ID = 998;
/** 기타 판매채널 */
const ETC_MALL_ID = 999;

/**
 * 국내 판매채널 옵션 리스트
 * - 국내 판매채널 id 범위: 1~100
 * - id 998 B2B는 국내 판매채널에만 해당
 * - id 999 기타는 국내 해외 판매채널 모두 해당
 */
const DOMESTIC_MALL_OPTION_LIST: TableColumnFilterOptionList<string> =
  MALL_OPTION_RAW_DATA_LIST.filter(
    (mall) =>
      mall.id < FIRST_OVERSEAS_MALL_ID ||
      mall.id === B2B_MALL_ID ||
      mall.id === ETC_MALL_ID
  ).map((mall) => ({ label: mall.korName, value: mall.name }));

/**
 * 해외 판매채널 옵션 리스트
 * - 해외 판매채널 id 범위: 100~
 * - id 999 기타는 국내 해외 판매채널 모두 해당
 */
const OVERSEAS_MALL_OPTION_LIST: TableColumnFilterOptionList<string> =
  MALL_OPTION_RAW_DATA_LIST.filter(
    (mall) => mall.id >= FIRST_OVERSEAS_MALL_ID && mall.id !== B2B_MALL_ID
  ).map((mall) => ({ label: mall.korName, value: mall.name }));

/** perPage 저장에 사용되는 로컬스토리지의 키값 */
const FULFILLMENT_TABLE_PER_PAGE_KEY = "fulfillmentTablePerPage";

export type { SaleChannelName, SaleChannelKorName };

export {
  FIRST_GROUP_SKU_ID_IN_DEV,
  FIRST_GROUP_SKU_ID_IN_PROD,
  ONE_MEGA_BYTE,
  MAX_UPLOAD_FILE_SIZE,
  MALL_OPTION_RAW_DATA_LIST,
  SALE_CHANNEL_MAP,
  DOMESTIC_MALL_OPTION_LIST,
  OVERSEAS_MALL_OPTION_LIST,
  FULFILLMENT_TABLE_PER_PAGE_KEY,
};
