import {
  PoRemittanceAccountType,
  PoRemittanceTransaction,
  POStatus,
  ProRemittanceFeeCharger,
} from "../../types/forwarding/purchaseOrder";
import {
  SendingMoneyFormStep2Dash1Key,
  SendingMoneyFormStep2Dash2Key,
} from "../../types/forwarding/sendingMoney";

import { InputSelectOption } from "../../headlessComponents/input/useInputSelect";

const LABEL_BY_PO_STATUS: Record<POStatus, string> = {
  registered: "등록 완료",
  waiting: "견적요청 확인 중",
  inProgress: "운송 진행중",
  finished: "종료",
};

const STATE_BADGE_STATE_BY_PO_STATUS: Record<POStatus, 1 | 2 | 3 | 4> = {
  registered: 1,
  waiting: 2,
  inProgress: 3,
  finished: 4,
};

const PO_REMITTANCE_FEE_CHARGER_OPTION_LIST: InputSelectOption<ProRemittanceFeeCharger>[] =
  [
    {
      label: "수취인",
      value: "receiver",
    },
    {
      label: "송금인",
      value: "sender",
    },
  ];

const PO_REMITTANCE_TRANSACTION_OPTION_LIST: InputSelectOption<PoRemittanceTransaction>[] =
  [
    {
      label: "SWIFT",
      value: "SWIFT_USD",
    },
    {
      label: "LOCAL BANK TRANSFER",
      value: "LOCAL_BANK_TRANSFER",
    },
  ];

const PO_REMITTANCE_STEP2_DASH1_LIST: SendingMoneyFormStep2Dash1Key[] = [
  "firstName",
  "lastName",
  "email",
  "phoneNumber",
  "companyName",
  "companyNumber",
  "bussName",
  "address1",
  "address2",
  "city",
  "state",
  "taxId",
  "zipcode",
];

const PO_REMITTANCE_STEP2_DASH2_LIST: SendingMoneyFormStep2Dash2Key[] = [
  "accountNumber",
  "accountType",
  "bankName",
  "branchCode",
  "bsbNumber",
  "clabe",
  "ibanCode",
  "ifsCode",
  "routingNumber",
  "sortCode",
  "swiftBicCode",
  "bankCode",
];

const PO_REMITTANCE_ACCOUNT_TYPE_OPTION_LIST: InputSelectOption<PoRemittanceAccountType>[] =
  [
    {
      label: "CURRENT_GENERAL",
      value: "CURRENT_GENERAL",
    },
    {
      label: "CHECKING",
      value: "CHECKING",
    },

    {
      label: "SAVING",
      value: "SAVING",
    },
  ];

export {
  PO_REMITTANCE_STEP2_DASH1_LIST,
  PO_REMITTANCE_STEP2_DASH2_LIST,
  LABEL_BY_PO_STATUS,
  STATE_BADGE_STATE_BY_PO_STATUS,
  PO_REMITTANCE_FEE_CHARGER_OPTION_LIST,
  PO_REMITTANCE_TRANSACTION_OPTION_LIST,
  PO_REMITTANCE_ACCOUNT_TYPE_OPTION_LIST,
};
