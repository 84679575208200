import { useEffect, useRef } from "react";

import { useOverlayContext } from "./OverlayStackProvider";

/**
 * Overlay 요소(Modal, SideSheet)의 stack에 등록/해제하는 hook.
 * OverlayStackProvider 로 감싸진 하위 컴포넌트에서 사용. (shipda-kr 전체에는 적용되어있음)
 * onClose 나 onSubClose 함수를 전달할 때, useCallback으로 감싸서 전달하는 것을 권장.
 */
export const useOverlayStack = ({
  isActive,
  onClose,
  isActiveSub,
  onSubClose,
}: {
  isActive: boolean;
  onClose?: () => void;
  isActiveSub?: boolean;
  onSubClose?: () => void;
}) => {
  const {
    registerOverlay,
    unregisterOverlay,
    registerSubOverlay,
    unregisterSubOverlay,
  } = useOverlayContext();

  // stack 에서 관리하는 현재 요소의 id를 저장.
  const currentElementId = useRef<number | null>(null);

  // 요소가 active 되면 stack 에 등록되고, esc 키를 누르거나 다른 동작으로 요소를 닫거나 이탈하면 stack 에서 제외됩니다.
  useEffect(() => {
    // 모달 활성화 여부에 따라 Overlay Stack 에 등록, 해제
    if (!isActive) return unregisterOverlay(currentElementId.current);

    currentElementId.current = registerOverlay({ isActive, onClose });

    // 컴포넌트가 언마운트 될 때 Overlay Stack에서 제거
    return () => {
      unregisterOverlay(currentElementId.current);
    };
  }, [isActive, onClose, registerOverlay, unregisterOverlay]);

  // SideSheet 위에 SubSideSheet 이 중첩으로 열리는 경우, 메인 SideSheet stack 요소에 subClose 가 등록됩니다.
  useEffect(() => {
    if (!currentElementId.current) return;

    if (!onSubClose) return;

    if (isActiveSub) {
      registerSubOverlay({ id: currentElementId.current, onSubClose });
    } else {
      unregisterSubOverlay(currentElementId.current);
    }
  }, [isActiveSub, onSubClose, registerSubOverlay, unregisterSubOverlay]);

  return { isActive, onClose, id: currentElementId.current };
};
