import { TableHeadFilterOption } from "@sellernote/_shared-for-admin/src/hooks/common/useTableHeadFilter";

import { ForwardingManagersId } from "../../types/forwarding/adminUser";

import { IS_UNDER_PRODUCTION } from "../../constants";

const ADMIN_FORWARDING_MANAGERS = {
  sonSukKyun: {
    id: IS_UNDER_PRODUCTION ? 10 : 46,
    accountId: "max",
    name: "손석균",
  },

  jengJeongHoon: {
    id: IS_UNDER_PRODUCTION ? 11 : 51,
    accountId: "jhjeong",
    name: "정정훈",
  },

  hwangHoJin: {
    id: IS_UNDER_PRODUCTION ? 12 : 52,
    accountId: "hjhwang",
    name: "황호진",
  },

  bangHyunJung: {
    id: IS_UNDER_PRODUCTION ? 16 : 55,
    accountId: "hjbang",
    name: "방현정",
  },

  leeJungho: {
    id: IS_UNDER_PRODUCTION ? 50 : 101,
    accountId: "test-admin",
    name: "이중호",
  },

  parkSeungHyeok: {
    id: IS_UNDER_PRODUCTION ? 53 : 107,
    accountId: "hyeok",
    name: "박승혁",
  },

  leeBoMi: {
    id: IS_UNDER_PRODUCTION ? 52 : 108,
    accountId: "BOMI",
    name: "이보미",
  },

  HawngDonghee: {
    id: IS_UNDER_PRODUCTION ? 55 : 110,
    accountId: "donghee50",
    name: "황동희",
  },

  JeonJuRi: {
    id: IS_UNDER_PRODUCTION ? 56 : 111,
    accountId: "juri",
    name: "전주리",
  },

  choiHyukJoong: {
    id: IS_UNDER_PRODUCTION ? 59 : 112,
    accountId: "choihj",
    name: "최혁중",
  },

  LeeChangeJoon: {
    id: IS_UNDER_PRODUCTION ? 62 : 113,
    accountId: "cj",
    name: "이창준",
  },

  ChoiKiHo: {
    id: IS_UNDER_PRODUCTION ? 63 : 114,
    accountId: "kyo",
    name: "채기호",
  },
};

const ADMIN_FORWARDING_MANAGER_OPTION_LIST: TableHeadFilterOption<ForwardingManagersId>[] =
  [
    {
      label: ADMIN_FORWARDING_MANAGERS.sonSukKyun.name,
      value: ADMIN_FORWARDING_MANAGERS.sonSukKyun.id,
    },
    {
      label: ADMIN_FORWARDING_MANAGERS.jengJeongHoon.name,
      value: ADMIN_FORWARDING_MANAGERS.jengJeongHoon.id,
    },
    {
      label: ADMIN_FORWARDING_MANAGERS.hwangHoJin.name,
      value: ADMIN_FORWARDING_MANAGERS.hwangHoJin.id,
    },
    {
      label: ADMIN_FORWARDING_MANAGERS.leeJungho.name,
      value: ADMIN_FORWARDING_MANAGERS.leeJungho.id,
    },
    {
      label: ADMIN_FORWARDING_MANAGERS.parkSeungHyeok.name,
      value: ADMIN_FORWARDING_MANAGERS.parkSeungHyeok.id,
    },
    {
      label: ADMIN_FORWARDING_MANAGERS.leeBoMi.name,
      value: ADMIN_FORWARDING_MANAGERS.leeBoMi.id,
    },
    {
      label: ADMIN_FORWARDING_MANAGERS.HawngDonghee.name,
      value: ADMIN_FORWARDING_MANAGERS.HawngDonghee.id,
    },
    {
      label: ADMIN_FORWARDING_MANAGERS.JeonJuRi.name,
      value: ADMIN_FORWARDING_MANAGERS.JeonJuRi.id,
    },
    {
      label: ADMIN_FORWARDING_MANAGERS.choiHyukJoong.name,
      value: ADMIN_FORWARDING_MANAGERS.choiHyukJoong.id,
    },
    {
      label: ADMIN_FORWARDING_MANAGERS.LeeChangeJoon.name,
      value: ADMIN_FORWARDING_MANAGERS.LeeChangeJoon.id,
    },
    {
      label: ADMIN_FORWARDING_MANAGERS.ChoiKiHo.name,
      value: ADMIN_FORWARDING_MANAGERS.ChoiKiHo.id,
    },
  ];

const ADMIN_FORWARDING_MANAGER_INFO_FOR_TRELLO_LIST = [
  {
    id: IS_UNDER_PRODUCTION ? 12 : 52,
    backgroundColor: "#e0f7e1",
    border: "#40c445",
    name: "황호진",
  },
  {
    id: IS_UNDER_PRODUCTION ? 11 : 51,
    backgroundColor: "#bae7ff",
    border: "#40a9ff",
    name: "정정훈",
  },
  {
    id: IS_UNDER_PRODUCTION ? 10 : 46,
    backgroundColor: "#ffeed9",
    border: "#fe9820",
    name: "손석균",
  },
  {
    id: IS_UNDER_PRODUCTION ? 50 : 101,
    backgroundColor: "#d7ccc8",
    border: "#6d4c41",
    name: "이중호",
  },
  {
    id: IS_UNDER_PRODUCTION ? 53 : 107,
    backgroundColor: "#c0c0c0",
    border: "#424242",
    name: "박승혁",
  },
  {
    id: IS_UNDER_PRODUCTION ? 52 : 108,
    backgroundColor: "#e2b3a3",
    border: "#e2b3a3",
    name: "이보미",
  },
  {
    id: IS_UNDER_PRODUCTION ? 55 : 110,
    backgroundColor: "#fff9c4",
    border: "#fff176",
    name: "황동희",
  },
  {
    id: IS_UNDER_PRODUCTION ? 56 : 111,
    backgroundColor: "#f8bbd0",
    border: "#e91e63",
    name: "전주리",
  },
  {
    id: IS_UNDER_PRODUCTION ? 59 : 112,
    backgroundColor: "#e1bee7",
    border: "#9c27b0",
    name: "최혁중",
  },
  {
    id: IS_UNDER_PRODUCTION ? 62 : 113,
    backgroundColor: "#C3B091",
    border: "#827717",
    name: "이창준",
  },
];

export {
  ADMIN_FORWARDING_MANAGERS,
  ADMIN_FORWARDING_MANAGER_OPTION_LIST,
  ADMIN_FORWARDING_MANAGER_INFO_FOR_TRELLO_LIST,
};
