import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useRef,
  useState,
} from "react";

import useCloseWithEscKey from "../../hooks/common/useCloseWithEscKey";
import { OverlayInfo, OverlayStackContextType } from "./types";

const OverlayStackContext = createContext<OverlayStackContextType | undefined>(
  undefined
);

/**
 * Overlay 요소(Modal, SideSheet 통칭하는 UI)의 stack을 관리하는 Provider
 * stack에 등록/해제하려면 useOverlayStack hook 사용
 *
 * stack에 등록/해제되는 순서대로 열림/닫힘을 관리한다. (명시적으로 닫기가 없는 (onClose 사용하지 않는) 모달은 닫히지 않는다. (정책적 결정))
 * stack에 등록된 요소 중 가장 상위에 있는 요소부터 닫힌다.
 * (SubSideSheet 는 Sub 요소로 관리)
 */
export const OverlayStackProvider = ({ children }: { children: ReactNode }) => {
  const [activeOverlayList, setActiveOverlayList] = useState<OverlayInfo[]>([]);

  const nextIdRef = useRef(1);

  const registerOverlay = useCallback(
    ({ isActive, onClose }: Pick<OverlayInfo, "isActive" | "onClose">) => {
      const id = nextIdRef.current++;

      setActiveOverlayList((prevOverlays) => [
        ...prevOverlays,
        { id, isActive, onClose },
      ]);

      return id;
    },
    []
  );

  const unregisterOverlay = useCallback((id: number | null) => {
    if (!id) return;

    setActiveOverlayList((prevOverlays) =>
      prevOverlays.filter((overlay) => overlay.id !== id)
    );
  }, []);

  const registerSubOverlay = useCallback(
    ({ id, onSubClose }: Pick<OverlayInfo, "id" | "onSubClose">) => {
      setActiveOverlayList((prevOverlays) =>
        prevOverlays.map((overlay) =>
          overlay.id === id ? { ...overlay, onSubClose } : overlay
        )
      );
    },
    []
  );

  const unregisterSubOverlay = useCallback((id: number | null) => {
    if (!id) return;

    setActiveOverlayList((prevOverlays) =>
      prevOverlays.map((overlay) =>
        overlay.id === id ? { ...overlay, onSubClose: undefined } : overlay
      )
    );
  }, []);

  // 가장 상위에 있는 요소부터 닫는다. Sub 요소가 있는 경우 Sub 요소만 닫는다.
  const closeTopOverlay = useCallback(() => {
    if (activeOverlayList.length) {
      const topOverlay = activeOverlayList[activeOverlayList.length - 1];

      if (topOverlay.onSubClose) {
        topOverlay.onSubClose();

        return;
      }

      topOverlay.onClose?.();
    }
  }, [activeOverlayList]);

  useCloseWithEscKey({ onClose: closeTopOverlay });

  return (
    <OverlayStackContext.Provider
      value={{
        registerOverlay,
        unregisterOverlay,
        registerSubOverlay,
        unregisterSubOverlay,
      }}
    >
      {children}
    </OverlayStackContext.Provider>
  );
};

export const useOverlayContext = () => {
  const context = useContext(OverlayStackContext);

  if (context === undefined) {
    throw new Error(
      "useOverlayContext must be used within a OverlayStackProvider"
    );
  }

  return context;
};
