import { useState } from "react";

/**
 *
 * @param key history state 객체의 키로 지정하는 값
 * @param defaultTo history state 객체에 키와 값이 없을 경우 기본값
 * @returns state: defaultTo 값 혹은 history state 객체의 값, setState: history state 객체에 입력한 key로 값을 설정해주는 함수
 */
export default function useHistoryState<T>(
  key: string,
  defaultTo: T
): [T, (value: T) => void] {
  const [state, rawSetState] = useState(() => {
    const value = window.history.state && window.history.state[key];
    return value || defaultTo;
  });

  function setState(value: T) {
    window.history.replaceState(
      { ...window.history.state, [key]: value },
      document.title
    );
    rawSetState(value);
  }

  return [state, setState];
}
