import { useState } from "react";

/**
 * sessionStorage 저장 시 자동으로 변환해주고 useState처럼 사용하는 커스텀훅
 * 해당 훅에서는 initialValue를 받지 않고 기본 값으로 null를 리턴한다.
 */
export default function useSessionStorage<T>(key: string) {
  const getSessionStorageValue = () => {
    /** next.js에서 초기 랜더링 시 window객체가 없는 상황을 반영 */
    if (typeof window === "undefined") {
      return null;
    }

    const item = window.sessionStorage.getItem(key);

    if (!item) return null;

    return item;
  };

  const [storageValue, setStorageValue] = useState<string | null>(
    getSessionStorageValue
  );

  const parseValue = (value: string | null): T | null => {
    if (value === null) return null;

    return JSON.parse(value) as T;
  };

  /** stringify된 value를 state와 sessionStorage에 set한다. */
  const setValue = (newValue: T | ((prevValue: T | null) => T)) => {
    const stringifiedValue =
      newValue instanceof Function
        ? JSON.stringify(newValue(parseValue(storageValue)))
        : JSON.stringify(newValue);

    setStorageValue(stringifiedValue);

    if (typeof window !== "undefined") {
      window.sessionStorage.setItem(key, stringifiedValue);
    }
  };

  const value = (() => {
    return parseValue(storageValue);
  })();

  return [value, setValue] as const;
}
