import { useCallback, useEffect } from "react";

function useCloseWithEscKey({ onClose }: { onClose?: () => void }) {
  const handleEscKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (!onClose) return;

      if (e.key === "Escape") {
        onClose();
      }
    },
    [onClose]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleEscKeyDown);

    return () => {
      document.removeEventListener("keydown", handleEscKeyDown);
    };
  }, [handleEscKeyDown]);
}

export default useCloseWithEscKey;
