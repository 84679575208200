import { Incoterms } from "../../types/common/common";

/**
 * 선택가능한 모든 인코텀즈 option list
 */
const INCOTERMS_OPTION_LIST: { label: string; value: Incoterms }[] = [
  {
    label: "FOB",
    value: "FOB",
  },
  {
    label: "CFR",
    value: "CFR",
  },
  {
    label: "CPT",
    value: "CPT",
  },
  {
    label: "EXW",
    value: "EXW",
  },
  {
    label: "FCA",
    value: "FCA",
  },
  {
    label: "CIF",
    value: "CIF",
  },
  {
    label: "CIP",
    value: "CIP",
  },
  {
    label: "DDP",
    value: "DDP",
  },
  {
    label: "DAP",
    value: "DAP",
  },
];

export { INCOTERMS_OPTION_LIST };
