import { TFunction } from "react-i18next";

/**
 * i18next의 initialize되는 시점을 맞추기 위해 props로 t함수 전달
 */
const SELECT_LABEL_BY_MEMBER_STATUS = (t: TFunction) => {
  return {
    waiting: t("team:멤버관리_검색_필터_상태_초대전송됨"),
    join: t("team:멤버관리_검색_필터_상태_활성"),
    decline: t("team:멤버관리_검색_필터_상태_거절"),
  };
};

/**
 * i18next의 initialize되는 시점을 맞추기 위해 props로 t함수 전달
 */
const SELECT_LABEL_BY_AUTHORITY_STATUS = (t: TFunction) => {
  return {
    write: t("team:멤버관리_권한_등록_편집"),
    none: t("team:멤버관리_권한_권한없음"),
  };
};

export { SELECT_LABEL_BY_MEMBER_STATUS, SELECT_LABEL_BY_AUTHORITY_STATUS };
