import React from "react";

import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";

import { ImageProps } from "../../types/common/common";

import { APP_TYPE } from "../../constants";
import DefaultLoadingGif from "./loading.gif";
import BofulLoadingLogo from "./loading_logo_boful.png";
import Styled from "./index.styles";

function DefaultLoading({
  active,
  label,
  Image,
  className,
  uiType,
}: {
  active: boolean;
  label?: React.ReactNode;
  Image?: ({ src, alt, layout, priority }: ImageProps) => JSX.Element;
  className?: string;
  uiType: "fullscreen" | "parentBy";
}) {
  if (!active) {
    return null;
  }

  return (
    <Styled.defaultContainer
      active={active}
      uiType={uiType}
      className={`${className ? className : ""} loading`}
    >
      <Styled.defaultImageWrapper>
        {Image ? (
          <Image src={DefaultLoadingGif} alt="loading" layout="fill" priority />
        ) : (
          <img src={DefaultLoadingGif} alt="loading" />
        )}
      </Styled.defaultImageWrapper>

      <div className="label">{label}</div>
    </Styled.defaultContainer>
  );
}

function BofulLoading({
  active,
  className,
  label,
  uiType,
}: {
  active: boolean;
  className?: string;
  label?: React.ReactNode;
  uiType: "fullscreen" | "parentBy";
}) {
  if (!active) {
    return null;
  }
  return (
    <Styled.bofulContainer
      active={active}
      uiType={uiType}
      className={`${className ? className : ""} loading loading-boful`}
    >
      <img src={BofulLoadingLogo} alt="loading" />

      <Styled.bofulLoader />
      {/* TODO: API 순차 호출 시 병목점이 어디인지 확인을 위하여 임시 라벨을 추가함 */}
      <div style={{ color: COLOR.white, marginTop: 20 }}>{label}</div>
    </Styled.bofulContainer>
  );
}

export default function Loading({
  label,
  active,
  Image,
  className,
  uiType = "fullscreen",
}: {
  active: boolean;
  label?: React.ReactNode;
  Image?: ({ src, alt, layout, priority }: ImageProps) => JSX.Element;
  /**
   * boful만 로딩바가 달라서 이렇게 처리함.
   */
  className?: string;
  /**
   * uiType을 전달하지 않을 경우 기본값 > fullscreen
   *
   * @example
   * fullscreen - 전체화면으로 적용 (fixed, 100vw, 100vh)
   * parentBy - 부모 기준으로 적용 (absolute, 100%, 100%)
   */
  uiType?: "fullscreen" | "parentBy";
}) {
  if (!active) {
    return null;
  }
  return APP_TYPE === "Boful" || APP_TYPE === "BofulMobile" ? (
    <BofulLoading
      active={active}
      label={label}
      uiType={uiType}
      className={className}
    />
  ) : (
    <DefaultLoading
      active={active}
      label={label}
      className={className}
      uiType={uiType}
      Image={Image}
    />
  );
}
